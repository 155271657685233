import React, { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Helmet } from "react-helmet"
import { SanitySettings } from "@utils/globalTypes"
import favicon from "../../../images/favicon.png"

type Props = {
  title?: string
  description?: string
  lang?: string
  location: string
  meta?: any[]
}

const SEO: FC<Props> = (props) => {
  const { lang = "en", location } = props
  const {
    sanitySiteSettings: {
      title,
      openGraph: { description },
    },
  }: { sanitySiteSettings: SanitySettings } = useStaticQuery(query)
  const metaDescription = props.description || description
  const metaTitle = props.title || title
  const scrollClass =
    props.location && props.location.startsWith("/employees/") ? "scroll" : null
  return (
    <Helmet bodyAttributes={{ class: scrollClass }}>
      <html className={scrollClass} lang={lang} />
      <title>{metaTitle}</title>
      <link rel="icon" href={favicon} />
      <link rel="canonical" href={location} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
    </Helmet>
  )
}

const query = graphql`
  query {
    sanitySiteSettings {
      title
      openGraph {
        description
      }
    }
  }
`

export default SEO
